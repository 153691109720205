.nav-link {
    margin-left: 4rem;
    &:focus {
        font-weight: 700;
    }
}

.img {
    max-width: 138rem;
    max-height: 42.5rem;
}