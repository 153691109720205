@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);

// #background-video {
//     width: 100vw;
//     // height: 100vh; 
//     position: absolute;
//     // position: relative;
//     top: 0;
//     // top: 4.6rem;
//     z-index: -10;
// }

// .navbar-light .navbar-nav .nav-link {
//     color: rgba(0,0,0,0.7);
// }

.header {
    // height: 95vh;
    font-family: 'Spoqa Han Sans';
    margin-top: 7.5vh;
    // position: absolute;
    left: 14vw;
    top: 22rem;
    height: 100vh;
    // vertical-align: middle;
    // display: flex;
    background: url("./../../assets/bg-capsule2.png");
    background-size: cover;
    &-title {
        &-wrapper {
            width: 80vw;
            margin: auto;
            font-size: 3.6rem;
            padding-top: 20vh;
        }
    }
}

#cap-mobile {
    display: none;
}


/* Tablets (Landscape) */
@media (min-width: 768px) and (max-width: 1024px) {
    #logo {
        width: 7.2rem;
    }
    .navbar {
        padding: 0 4vw;
    }
    .header {
        top: 10rem;
        &-title-wrapper {
            font-size: 3rem;
        }
        &-btn {
            margin-top: 2rem;
        }
    }
    .feature {
        width: 12rem;
    }
    .about {
        &-box {
            width: 85%;
        }
    }
    .product {
        height: 84rem;
        &-item {
            &-img {
                &-dent {
                    width: 40vw;
                }
                &-cap {
                    width: 28vw;
                }
            }
            &-description {
                width: 26rem;
                padding: 0 2rem 1.4rem 2rem;
            }
            &-name {
                padding: 0 2rem 0.8rem 2rem;
            }
            &-button {
                &-wrapper {
                    padding-left: 2rem;
                }
            }
        }
    }
    .feature2 {
        width: 13rem;
        &-img {
            height: 5rem;
        }
        &-title {
            padding-bottom: 3rem;
        }
    }
    .notice {
        &-space {
            width: 28vw;
        }
    }
    .history {
        &-timeline {
            width: 80vw;
        }
    }

}

/* Low Resolution Tablets, Mobiles (Landscape) */
@media (min-width: 481px) and (max-width: 767px) {
    .header {
        &-title-wrapper {
            font-size: 2.6rem;
        }
    }

}

/* Smartphones Mobiles(portraits) */
@media (max-width: 480px) {
    #background-video {
        visibility: hidden;
        // height: 100vh;
        height: 50vh; //temp
    }
    .navbar {
        // padding: 0 6vw 0 6vw;
        padding: 0.5rem 1.5rem;
        height: auto;
    }
    .nav-link {
        margin: auto;
    }
    .navbar-light .navbar-toggler {
        border-color: transparent;
        padding: 0;
    }
    #logo {
        width: 6rem;
    }
    .header {
        background: url("./../../assets/notice-bg.png");
        background-size: cover;
        background-position-x: -110vw;

        top: 0;
        left: 0;
        position: relative;
        &-title-wrapper {
            font-size: 2rem;
            color: #666;
            text-align: center;
            position: relative;
            // top: -4.6rem;
            top: -0.8rem; //temp
        }
        &-btn {
            margin: 1.5rem auto 0 auto;
        }
    }
    .about {
        &-bg {
            height: 72rem;
            margin-bottom: 18rem;
            padding-top: 5rem;
        }
        &-phrase {
            font-size: 1.2rem;
            width: 14rem;
            margin: auto;
        }
        &-box {
            width: 80%;
            height: 66rem;
            top: 5rem;
        }
    }
    .feature {
        width: 12rem;
        padding: 1rem 0 2.4rem 0;
        &-title {
            padding-bottom: 1rem;
        }
    }

    .spec {
        margin: 1rem auto 0 auto;
        display: block;
    }

    .product {
        height: 96rem;
        &-title {
            font-size: 1.6rem;
            margin-bottom: 3rem;
        }
        &-item {
            &-left {
                display: block;
                width: 100%;
            }
            &-right {
                display: block;
                width: 100%;
                padding-left: 0;
            }
            &-name {
                padding: 0.5rem 0 0.5rem 0;
                text-align: center;
            }
            &-description {
                padding: 0;
                width: 17rem;
                margin: auto;
                text-align: center;
            }
            &-img {
                &-dent {
                    width: 18rem;
                }
                &-cap {
                    width: 16rem;
                }
            }
            &-button {
                &-wrapper {
                    padding: 0;
                    text-align: center;
                    margin-top: 1rem;
                }
            }
            
        }
    }
    .notice {
        &-box {
            height: 16rem;   
        }
        &-text {
            left: 0;
            display: block;
            text-align: center;
            margin-bottom: 1rem;
            &-title {
                font-size: 1.6rem;
            }
        }
        &-space {
            display: none;
        }
        &-btnwrapper {
            display: block;
            position: relative;
            text-align: center;
            right: 0;
        }
    }
    .history {
        height: 40rem;
        padding-top: 5rem;
        &-text {
            width: 16rem;
            margin: 0 auto 2rem auto;
        }
        &-timeline {
            display: none;
        }
    }
    .feature2 {
        width: 10rem;
        &-img {
            height: 5rem;
            &-wrapper {
                display: inline-block;
            }
        }
    }

    #cap-mobile {
        display: block;
    }

    #cap-web {
        display: none;
    }
}