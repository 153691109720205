.popup {
    border-radius: 0.4rem;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.3);
    line-height: 2;
    padding: 1rem;
    margin: auto;
    vertical-align: middle;
    font-size: 0.9rem;

    &.about {
        width: 80%;
        height: 28rem;
        background-color: white;
        text-align: center;
        position: relative;
        top: 6rem;
    }

    &.notice {
        width: 72vw;
        height: 14rem;
        background: url("./../../assets/notice-bg.png");
        background-size: "cover";
        padding: 4rem 0 4rem 0;
        font-size: 1rem;
    }

    &.infobox {
        display: inline-block;
        background-color: #fafafa;
        vertical-align: top;        
    }
}

.popupText{
    &-col1 {
        display: inline-block;
        margin-right: 1rem;
    }

    &-col2 {
        display: inline-block;
        color: #00AEF5;
    }

    &-spacer{
        height: 1rem;
    }
}