.button{
    font-family: 'Spoqa Han Sans';
    background-color: #2BC2FF;
    color: white;
    border-radius: 0.2rem;
    border: none;
    font-weight: 600;
    text-align: center;
    padding: 0 0.4rem 0 0.6rem;
    margin-right: 1.2rem;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.3);

    height: 2.4rem;
    width: 12rem;
    font-size: 1rem;

    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        background-color: #1198CF;
    }

    &.main {
        height: 3rem;
        width: 14rem;
    }
}