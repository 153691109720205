
.feature
{
    &-withDesc {
        width: 18vw;
        display: inline-block;
        vertical-align: top;
        margin: 0 3.2vw;
        padding-top: 3rem;

        &-img {
            width: 5rem;
            vertical-align: middle;
            &-wrapper {
                line-height: 8;
            }
        }
    }   

    &-onlyTitle {
        width: 16rem;
        display: inline-block;
        vertical-align: top;
        
        &-img {
            height: 6rem;
            opacity: 0.8;
            vertical-align: middle;
            &-wrapper {
                line-height: 8;
            }
        }
    }
}