.container {
    width:100%;
    margin-top: 10vh;
    margin-bottom: 2.5vh;

    &-inner{
        margin-top: 2.5vh;
        &-horizon{
            margin-top: 12.5vh;
            &-left {
                display: inline-block;
            }
            &-right {
                float:right;
                margin-right: -1rem;
            }
        }
    }
}

.editor {
    height: 50vh;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    padding-left: 10px;
}