.product {
    &-item {
        width: 80vw;
        margin: 0 auto 5rem auto;

        &-left {
            display: inline-block;
            width: 40vw;
        }
        &-right {
            display: inline-block;
            width: 40vw;
            padding-left: 3rem;
        }
        &-img {
            &-model {
                position: relative;
                z-index: 1;
                top: -18rem;
                right: -16rem;
            }
            &-dent {
                width: 40vw;
            }
        }
        &-buttonwrapper {
            text-align: left;
            padding-left: 4vw;
        }
    }
}