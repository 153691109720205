.main-footer {
    &-wrapper {
        padding: 1rem 0 1rem 0;
        background-color: #222222;
        text-align: center;
    }

    &-company {
        color: #a0a0a0;
    }
    
    &-copyright {
        color: #ffffff;
    }
}