::-webkit-scrollbar{width:2px;height:2px;}

.paycard {
    background-image: url('./../../../assets/cards/cards.svg');
    background-size: 1455px auto;
    background-position: -1168px 180px;
    width: 287px;
    height: 180px;
    border-radius: 3px;
}

.cardList {
    width: 5782px;
    margin-left: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)//translateY(-39%);
}

.cardItem {
    padding-left: 20px;
    padding-right: 20px;
} 

.viewport {
    display: block;
    text-align: left;
    float: none;
    position: relative;
    inset: auto;
    z-index: auto;
    width: 545px;
    height: 333px;
    margin: 0px;
    overflow: hidden;
    justify-content: center;
}

.cardText {
    font-family: 'Spoqa Han Sans';
    color: white;
    position: absolute;
    left: 20px;
    bottom: 24px;
    text-align: left;
}

.orderBtn {
    border: none;
    background-color: #34B2EA;
    font-size: 36px;
    width: 545px;
    height: 100px;
    color: white;
}