.text{
    font-family: 'Spoqa Han Sans'; 

    &-bold {
        font-weight: 600;
    }

    &.about {
        &-phrase{
            text-align: center;
            font-size: 1.8rem;
            color: white;
        }
    }

    &.product{
        &-title {
            text-align: center;
            font-size: 2rem;
            font-weight: 700;

            padding-top: 3rem;
            margin-bottom: 8rem;
        }

        &-name {
            text-align: left;
            font-weight: 600;
            font-size: 1.6rem;
            color: #2BC2FF;

            padding: 0 4vw 0.8rem 4vw;
        }

        &-description {
            width: 36vw;
            text-align: left;
            font-size: 0.9rem;
            color: #666;
            padding: 0 4vw 1vw 4vw;
        }
    }

    &.notice {
        &-title {
            font-size: 2rem;
            font-weight: 700;
        }
    }

    &.contact {
        height: 18rem;
        background-size: cover;
        text-align: center;

        &-phone {
            color: white;
            font-weight: 600;
        }
        &-mail {
            color: white;
        }
        &-addr {
            color: white;
        }
    }

    &.feature {
        &-withDesc{
            font-size: 0.95rem;

            &-title {
                padding-bottom: 2rem;
                color: #31BBF3;
                font-weight: 600;
            }
        
            &-content {
                color: #333333;
            }
        }

        &-onlyTitle{
            &-title {
                padding-bottom: 2rem;
                color: white;
                font-size: 0.9rem;
            }
        }
    }
    &.history{
        &-text {
            text-align: center;
            margin-bottom: 5rem;
            font-size: 1.5rem;
        }
    }

    &.terms {
        display: block;
        text-align: center;
        padding: 16px;
        text-decoration: none;
        
        &-link {
            color: white;
        }
        &-link:link {
            color: white;
        }
        &-link:hover {
            color: white;
        }
        &-link:active {
            color: white;
        }
    
    }
}