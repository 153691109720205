.contact {
    &-logo {
        margin: 1.5rem 0 1.5rem 0;
        width: 8rem;
    }
}

.terms-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}