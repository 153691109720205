.background{
    background-size: cover;
    text-align: center;

    &.about {
        background-color: grey;
        padding-top: 6rem;
        position: relative;
        height: 38rem;
        margin-bottom: 10rem;
    }

    &.product {
        height: 128rem;

        &-bg{
            &-1 {
                position: absolute;
                z-index: -1;
                width: 50vw;
                left: 0rem;
            }
            &-2 {
                position: absolute;
                z-index: -1;
                width: 50vw;
                right: 0rem;
            }
        }
    }

    &.history {
        background-size: cover;
        height: 36rem;
        color: white;
        padding-top: 8rem;
        &-text {
            margin-bottom: 2rem;
        }
        &-timeline {
            // width: 64vw;
            width: 60rem;
        }
    }

    &.contact {
        height: 12rem;
    }
}